.testimonial {
  .tiles-item-inner {
    background: get-color(light, 1);
  }
  .tiles-item {
    max-width: 240px;
  }
}

.testimonial-item-content {
  position: relative;

  &::before {
    content: "";
    display: block;
    width: 24px;
  }
  margin: auto;
}

.testimonial-item-footer {
  font-weight: 600;
  padding-top: 20px;

  &.has-top-divider {
    &::before {
      background: get-color(dark, 3);
    }
  }
}

.testimonial-item-link {
  color: get-color(secondary, 3);

  a {
    color: get-color(secondary, 3);
    text-decoration: none;

    &:hover {
      color: get-color(secondary, 2);
    }
  }
}
