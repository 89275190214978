sub {
  font-size: 85%;
}

.text-xxxs {
  font-size: 10px;
}

.text-sub {
  font-size: 16px;
}
