h3,
h4,
h5,
h6,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 700;
}

h1,
.h1,
h2,
.h2,
h3,
h4,
h5,
h6,
.h3,
.h4,
.h5,
.h6 {
  color: get-color(primary, 1);
}
